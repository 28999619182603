import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import screenfull from "screenfull";
import { motion } from "framer-motion";
import { ProctorContext } from "./CombineTest/TestProctor/ProctorContext";
import {
  Info,
  Wifi,
  Laptop,
  Battery,
  Globe,
  Chrome,
  Phone,
  ShieldAlert,
  Trash2,
  AlertTriangle,
  Clock,
  Keyboard,
  AlertOctagon,
  Camera,
  Monitor,
  MousePointer,
  Copy,
  Maximize,
  Eye,
} from "lucide-react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  Typography,
  Button,
  Box,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";

const Instruction = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [testInfo, setTestInfo] = useState(null);
  const [error, setError] = useState(null);
  const { startProctoring } = useContext(ProctorContext);
  const [proctoringInitialized, setProctoringInitialized] = useState(false);

  const instructionItems = [
    {
      icon: <Wifi className="w-6 h-6" />,
      text: "Ensure stable Internet connectivity.",
    },
    {
      icon: <Laptop className="w-6 h-6" />,
      text: "Using a laptop or desktop is highly recommended.",
    },
    {
      icon: <Battery className="w-6 h-6" />,
      text: "Make sure your device is fully charged. Arrange a power bank or UPS for uninterrupted power supply.",
    },
    {
      icon: <Globe className="w-6 h-6" />,
      text: "Have sufficient data in your Internet plan.",
    },
    {
      icon: <Chrome className="w-6 h-6" />,
      text: "Close all other browsers/tabs before starting the exam.",
    },
    {
      icon: <AlertTriangle className="w-6 h-6" />,
      text: "Do not switch to any other window/tab during the exam to avoid disqualification.",
    },
    {
      icon: <Phone className="w-6 h-6" />,
      text: "Avoid receiving calls if taking the exam on mobile, as it will be treated as switching windows.",
    },
    {
      icon: <ShieldAlert className="w-6 h-6" />,
      text: "Use an Ad Blocker to avoid unwanted pop-ups.",
    },
    {
      icon: <Trash2 className="w-6 h-6" />,
      text: "Clear your browser cache, history, and temporary files before the exam.",
    },
  ];

  const noteItems = [
    {
      icon: <Chrome className="w-6 h-6" />,
      text: "Use web browsers like Mozilla Firefox or Google Chrome.",
    },
    {
      icon: <AlertTriangle className="w-6 h-6" />,
      text: "Avoid using the back button or closing the browser during the exam.",
    },
    {
      icon: <Clock className="w-6 h-6" />,
      text: "You should start exam on the time provided otherwise if you join late then you can attempt the test for remaining time only.",
    },
    {
      icon: <Keyboard className="w-6 h-6" />,
      text: "Use of Ctrl, Alt, Tab, Shift keys will generate warning.",
    },
    {
      icon: <AlertOctagon className="w-6 h-6" />,
      text: "There will be only one warning after that the test will auto submit.",
    },
  ];

  useEffect(() => {
    try {
      const storedTestInfo = localStorage.getItem("testInfo");
      const email = localStorage.getItem("email");
      const testId = localStorage.getItem("testId");

      if (!storedTestInfo || !email || !testId) {
        throw new Error("Missing required test information");
      }

      const parsedTestInfo = JSON.parse(storedTestInfo);
      setTestInfo(parsedTestInfo);
    } catch (error) {
      console.error("Error loading test information:", error);
      setError(error.message);
      toast.error("Error loading test information");
      setTimeout(() => navigate("/attempt-test"), 2000);
    }
  }, [navigate]);

  const initializeProctoring = async () => {
    try {
      const testInfo = JSON.parse(localStorage.getItem("testInfo"));
      await startProctoring(testInfo.testId);
      setProctoringInitialized(true);
      return true;
    } catch (error) {
      console.error("Error initializing proctoring:", error);
      if (error.name === "NotAllowedError") {
        toast.error(
          "Camera and screen sharing access is required for this test"
        );
      }
      return false;
    }
  };

  const startExamBtn = async () => {
    if (!testInfo) {
      toast.error("Test information not available");
      return;
    }

    const proctoringSuccess = await initializeProctoring();
    if (proctoringSuccess) {
      setShowModal(true);
    }
  };

  const handleConfirmation = async () => {
    setShowModal(false);

    if (!proctoringInitialized) {
      toast.error("Proctoring must be initialized before starting the test");
      return;
    }

    try {
      if (screenfull.isEnabled) {
        await screenfull.request(document.documentElement);
      }

      navigate("/section-selection");
    } catch (error) {
      console.error("Error starting test:", error);
      toast.error("Failed to start test. Please try again.");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (error) {
    return (
      <div className="instruction-container">
        <div className="text-center">
          <AlertOctagon className="w-16 h-16 text-red-500 mx-auto mb-4" />
          <p className="text-red-600 mb-4">{error}</p>
          <p>Redirecting to attempt test page...</p>
        </div>
      </div>
    );
  }

  const steps = [
    {
      label: "Technical Requirements",
      items: [
        { icon: <Wifi />, text: "Ensure stable Internet connectivity." },
        {
          icon: <Laptop />,
          text: "Using a laptop or desktop is highly recommended.",
        },
        {
          icon: <Battery />,
          text: "Make sure your device is fully charged or connected to power.",
        },
        {
          icon: <Camera />,
          text: "Working webcam is required for proctoring.",
        },
        {
          icon: <Monitor />,
          text: "Screen sharing permission will be required.",
        },
      ],
    },
    {
      label: "Browser Setup",
      items: [
        { icon: <Chrome />, text: "Use Mozilla Firefox or Google Chrome." },
        {
          icon: <Globe />,
          text: "Have sufficient data in your Internet plan.",
        },
        { icon: <Trash2 />, text: "Clear your browser cache and history." },
        {
          icon: <ShieldAlert />,
          text: "Use an Ad Blocker to avoid unwanted pop-ups.",
        },
      ],
    },
    {
      label: "Test Rules",
      items: [
        {
          icon: <AlertTriangle />,
          text: "Do not switch windows/tabs during exam - automatic submission.",
        },
        {
          icon: <Keyboard />,
          text: "Ctrl, Alt, Tab, Shift keys usage will generate warning.",
        },
        {
          icon: <MousePointer />,
          text: "Right-clicking is not allowed during the test.",
        },
        { icon: <Copy />, text: "Copy-paste actions are not permitted." },
      ],
    },
    {
      label: "Important Warnings",
      items: [
        {
          icon: <AlertOctagon />,
          text: "Only one warning will be given before auto-submission.",
        },
        { icon: <Clock />, text: "Late joining reduces available test time." },
        {
          icon: <Maximize />,
          text: "Fullscreen mode must be maintained throughout.",
        },
        { icon: <Eye />, text: "Face must be visible in webcam at all times." },
      ],
    },
  ];

  return (
    <Container
      maxWidth="md"
      sx={{
        py: 4,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Info /> Online Examination Guidelines
        </Typography>
      </motion.div>

      <Paper
        elevation={3}
        sx={{ p: 3, flex: 1, mt: 3, display: "flex", flexDirection: "column" }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{ flex: 1 }}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>
                <Typography variant="h6">{step.label}</Typography>
              </StepLabel>
              <StepContent>
                <Grid container spacing={2}>
                  {step.items.map((item, idx) => (
                    <Grid item xs={12} key={idx}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Box sx={{ color: "primary.main" }}>{item.icon}</Box>
                        <Typography>{item.text}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Box sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    onClick={() => setActiveStep((prev) => prev + 1)}
                    sx={{ mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  {index > 0 && (
                    <Button onClick={() => setActiveStep((prev) => prev - 1)}>
                      Back
                    </Button>
                  )}
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        {activeStep === steps.length && (
          <Box
            sx={{
              p: 3,
              textAlign: "center",
              borderTop: 1,
              borderColor: "divider",
              mt: "auto",
            }}
          >
            <Typography gutterBottom variant="h6" color="primary">
              All instructions have been reviewed
            </Typography>
            <Typography gutterBottom color="text.secondary" sx={{ mb: 2 }}>
              By clicking Start Test, you agree to allow camera access and
              screen sharing for proctoring
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={startExamBtn}
              size="large"
              sx={{
                minWidth: 200,
                py: 1.5,
              }}
            >
              Start Test
            </Button>
          </Box>
        )}
      </Paper>

      <Dialog open={showModal} onClose={closeModal}>
        <DialogTitle>Close Other Tabs and Applications</DialogTitle>
        <DialogContent>
          <Typography>
            Please make sure to close all other browser tabs and applications
            before starting the test.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            onClick={handleConfirmation}
            variant="contained"
            color="success"
          >
            I have closed them
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Instruction;
