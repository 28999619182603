import React, { useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Stack,
  Tooltip,
  Fade,
  Modal,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CodeIcon from "@mui/icons-material/Code";
import QuizIcon from "@mui/icons-material/Quiz";
import CloseIcon from "@mui/icons-material/Close";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import EditQuestionAndTestcases from "../EditQuestionAndTestcases";
import MCQQuestions from "./MCQQuestions";
import AiCodingQuestion from "./AiCodingQuestion";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const UserQuestions = ({
  questions,
  handleCreateQuestion,
  handleBulkCreateQuestionBtnClick,
  handleDeleteQuestion,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTestCases, setSelectedTestCases] = useState([]);
  const [showAiQuestion, setShowAiQuestion] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleEditQuestion = (questionId) => {
    setEditingQuestionId(questionId);
  };

  const handleCloseEdit = () => {
    setEditingQuestionId(null);
  };

  const handleShowTestCases = (testCases) => {
    setSelectedTestCases(testCases);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTestCases([]);
  };

  const handleShowAiQuestion = () => {
    setShowAiQuestion(true);
  };

  const handleCloseAiQuestion = () => {
    setShowAiQuestion(false);
  };

  if (editingQuestionId) {
    return (
      <Fade in={true}>
        <div>
          <EditQuestionAndTestcases
            questionId={editingQuestionId}
            onClose={handleCloseEdit}
          />
        </div>
      </Fade>
    );
  }

  if (showAiQuestion) {
    return (
      <Fade in={true}>
        <div>
          <AiCodingQuestion onClose={handleCloseAiQuestion} />
        </div>
      </Fade>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          borderBottom: "1px solid #e0e0e0",
          px: 3,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{
            "& .MuiTabs-flexContainer": {
              justifyContent: "flex-start",
            },
            "& .MuiTab-root": {
              minHeight: "48px",
              fontWeight: 500,
              fontSize: "0.95rem",
              textTransform: "none",
              mr: 1,
              "&:hover": {
                opacity: 0.8,
                transition: "all 0.2s",
              },
              "&.Mui-selected": {
                fontWeight: 600,
                color: "primary.main",
              },
            },
          }}
        >
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CodeIcon sx={{ fontSize: 20 }} />
                <span>Coding Questions</span>
              </Box>
            }
          />
          <Tab
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <QuizIcon sx={{ fontSize: 20 }} />
                <span>MCQ Questions</span>
              </Box>
            }
          />
        </Tabs>
      </Box>

      <TabPanel value={selectedTab} index={0}>
        <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateQuestion}
          >
            Create Question
          </Button>
          <Button
            variant="contained"
            onClick={handleBulkCreateQuestionBtnClick}
            startIcon={<FileUploadIcon />}
          >
            Import Bulk Questions
          </Button>

          <Button
            variant="contained"
            onClick={handleShowAiQuestion}
            startIcon={<AutoAwesomeIcon />}
            sx={{
              background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
              boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
              "&:hover": {
                background: "linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)",
              },
            }}
          >
            Generate AI Questions
          </Button>
        </Stack>

        <TableContainer component={Paper} elevation={2}>
          <Table sx={{ minWidth: 650 }} aria-label="questions table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "primary.main" }}>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", width: "25%" }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", width: "55%" }}
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: "bold", width: "20%" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((question) => (
                <TableRow
                  key={question.id}
                  sx={{ "&:hover": { backgroundColor: "action.hover" } }}
                >
                  <TableCell>{question.title}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        color: "text.secondary",
                        maxHeight: "100px",
                        overflowY: "auto",
                        "&::-webkit-scrollbar": {
                          width: "8px",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "#f1f1f1",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#888",
                          borderRadius: "4px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          background: "#555",
                        },
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: question.content }}
                        style={{
                          "& p": { margin: "0.5em 0" },
                          "& blockquote": {
                            borderLeft: "3px solid #ccc",
                            margin: "1em 0",
                            paddingLeft: "1em",
                          },
                          "& strong": { fontWeight: "bold" },
                          "& em": { fontStyle: "italic" },
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="View Test Cases">
                        <IconButton
                          color="info"
                          onClick={() =>
                            handleShowTestCases(question.testCases)
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Question">
                        <IconButton
                          color="primary"
                          onClick={() => handleEditQuestion(question.id)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Question">
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteQuestion(question.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <MCQQuestions />
      </TabPanel>

      {/* Test Cases Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="test-cases-modal"
        aria-describedby="test-cases-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" component="h2">
              Test Cases
            </Typography>
            <IconButton onClick={handleCloseModal} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <List sx={{ maxHeight: 400, overflow: "auto" }}>
            {selectedTestCases && selectedTestCases.length > 0 ? (
              selectedTestCases.map((testCase, index) => (
                <React.Fragment key={testCase.id}>
                  <ListItem>
                    <ListItemText
                      primary={`Test Case ${index + 1}`}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            display="block"
                            color="text.primary"
                          >
                            Input: {testCase.inputContent}
                          </Typography>
                          <Typography
                            component="span"
                            display="block"
                            color="text.primary"
                          >
                            Output: {testCase.outputContent}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  {index < selectedTestCases.length - 1 && <Divider />}
                </React.Fragment>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No test cases found" />
              </ListItem>
            )}
          </List>
        </Box>
      </Modal>
    </>
  );
};

export default UserQuestions;
