// import React from "react";

// const OAuthLogin = () => {
//   const handleOAuthLogin = (provider) => {
//     window.open(`${import.meta.env.VITE_BASE_URL}/auth/${provider}`, "_self");
//   };

//   return (
//     <div className="flex flex-col items-center space-y-4">
//       <p>Are you a candidate? Sign in with below options</p>
//       <button
//         onClick={() => handleOAuthLogin("google")}
//         className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//       >
//         Sign in with Google
//       </button>
//       <button
//         onClick={() => handleOAuthLogin("facebook")}
//         className="px-6 py-2 bg-blue-700 text-white font-semibold rounded-lg shadow-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50"
//       >
//         Sign in with Facebook
//       </button>
//     </div>
//   );
// };

// export default OAuthLogin;
    




// import React from "react";

// const OAuthLogin = () => {
//   const handleOAuthLogin = (provider) => {
//     window.open(`${import.meta.env.VITE_BASE_URL}/auth/${provider}`, "_self");
//   };

//   return (
//     <div className="flex flex-col items-center space-y-4">
//       <p>Are you a candidate? Sign in with the options below</p>

//       {/* Google Login */}
//       <button
//         onClick={() => handleOAuthLogin("google")}
//         className="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//       >
//         Sign in with Google
//       </button>

//       {/* Facebook Login */}
//       <button
//         onClick={() => handleOAuthLogin("facebook")}
//         className="px-6 py-2 bg-blue-700 text-white font-semibold rounded-lg shadow-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50"
//       >
//         Sign in with Facebook
//       </button>

//       {/* LinkedIn Login */}
//       <button
//         onClick={() => handleOAuthLogin("linkedin")}
//         className="px-6 py-2 bg-blue-900 text-white font-semibold rounded-lg shadow-md hover:bg-blue-950 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-opacity-50"
//       >
//         Sign in with LinkedIn
//       </button>

//       {/* GitHub Login */}
//       <button
//         onClick={() => handleOAuthLogin("github")}
//         className="px-6 py-2 bg-gray-800 text-white font-semibold rounded-lg shadow-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-opacity-50"
//       >
//         Sign in with GitHub
//       </button>
//     </div>
//   );
// };

// export default OAuthLogin;

import React from "react";
import { Github, Linkedin } from "lucide-react";

const OAuthLogin = () => {
  const handleOAuthLogin = (provider) => {
    window.open(`${import.meta.env.VITE_BASE_URL}/auth/${provider}`, "_self");
  };

  const tooltipClasses = "absolute -bottom-8 left-1/2 -translate-x-1/2 hidden group-hover:block bg-gray-800 text-white text-xs py-1 px-2 rounded whitespace-nowrap z-50";

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="space-y-8">
        <p className="text-center text-gray-700 text-lg font-medium">
          Sign in to continue
        </p>

        <div className="flex justify-center items-center gap-8">
          {/* Google Login */}
          <div className="group relative">
            <button
              onClick={() => handleOAuthLogin("google")}
              className="p-2 hover:opacity-80 transition-opacity duration-200 focus:outline-none"
              aria-label="Google"
            >
              <svg 
                className="w-8 h-8" 
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                  fill="#4285F4"
                />
                <path
                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                  fill="#34A853"
                />
                <path
                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                  fill="#FBBC05"
                />
                <path
                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                  fill="#EA4335"
                />
              </svg>
            </button>
            <div className={tooltipClasses}>
              Google
            </div>
          </div>

          {/* Facebook Login */}
          <div className="group relative">
            <button
              onClick={() => handleOAuthLogin("facebook")}
              className="p-2 hover:opacity-80 transition-opacity duration-200 focus:outline-none"
              aria-label="Facebook"
            >
              <svg
                className="w-8 h-8"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                  fill="#1877F2"
                />
              </svg>
            </button>
            <div className={tooltipClasses} style={{ minWidth: 'max-content' }}>
              Facebook
            </div>
          </div>

          {/* LinkedIn Login */}
          <div className="group relative">
            <button
              onClick={() => handleOAuthLogin("linkedin")}
              className="p-2 hover:opacity-80 transition-opacity duration-200 focus:outline-none"
              aria-label="LinkedIn"
            >
              <Linkedin className="w-8 h-8 text-[#0A66C2]" />
            </button>
            <div className={tooltipClasses} style={{ minWidth: 'max-content' }}>
              LinkedIn
            </div>
          </div>

          {/* GitHub Login */}
          <div className="group relative">
            <button
              onClick={() => handleOAuthLogin("github")}
              className="p-2 hover:opacity-80 transition-opacity duration-200 focus:outline-none"
              aria-label="GitHub"
            >
              <Github className="w-8 h-8" />
            </button>
            <div className={tooltipClasses}>
              GitHub
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OAuthLogin;