import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import { CheckCircle, Home, Flame, Star, Award } from "lucide-react";

const IconRating = ({ value, onChange, icon: Icon, activeColor }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 0.5,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {[...Array(10)].map((_, index) => {
        const ratingValue = index + 1;
        return (
          <motion.div
            key={ratingValue}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <IconButton
              onClick={() => onChange(ratingValue)}
              sx={{ p: 0.5, position: "relative" }}
            >
              <Icon
                size={32}
                className={ratingValue <= value ? "fill-current" : ""}
                style={{
                  color: ratingValue <= value ? activeColor : "#e2e8f0",
                  stroke: ratingValue <= value ? activeColor : "#94a3b8",
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  position: "absolute",
                  bottom: -18,
                  left: "50%",
                  transform: "translateX(-50%)",
                  color: ratingValue <= value ? activeColor : "text.secondary",
                  fontWeight: 500,
                }}
              >
                {ratingValue}
              </Typography>
            </IconButton>
          </motion.div>
        );
      })}
    </Box>
  );
};

const feedbackCategories = [
  {
    title: "Overall Experience",
    description: "Rate how well CirrusCypher met your testing needs",
    key: "platform",
    icon: Flame,
    color: "#f97316",
  },
  {
    title: "Interface & Navigation",
    description: "How smooth and intuitive was your testing journey?",
    key: "interface",
    icon: Star,
    color: "#eab308",
  },
  {
    title: "Proctoring Experience",
    description:
      "Rate the balance between security and comfort during monitoring",
    key: "proctoring",
    icon: Award,
    color: "#8b5cf6",
  },
];

const MCQTestSubmitted = () => {
  const navigate = useNavigate();
  const [ratings, setRatings] = useState({
    platform: 0,
    interface: 0,
    proctoring: 0,
  });
  const [feedback, setFeedback] = useState("");

  const handleSubmit = () => {
    // Submit logic here
    console.log({ ratings, feedback });
    navigate("/");
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        bgcolor: "background.default",
        py: 4,
      }}
    >
      <Container maxWidth="lg">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: 4,
            background: "linear-gradient(135deg, #f8fafc 0%, #ffffff 100%)",
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid container spacing={6} sx={{ flex: 1 }}>
            {/* Left Side - Success Illustration */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 200 }}
              >
                <CheckCircle size={120} className="text-green-500" />
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    mt: 4,
                    fontWeight: 700,
                    textAlign: "center",
                    background:
                      "linear-gradient(45deg, #2563eb 30%, #3b82f6 90%)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Test Submitted!
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 2,
                    textAlign: "center",
                    color: "text.secondary",
                  }}
                >
                  Your results have been securely stored. Thank you for choosing
                  CirrusCypher!
                </Typography>
              </motion.div>
            </Grid>

            {/* Right Side - Feedback Form */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    mb: 4,
                    textAlign: "center",
                    background:
                      "linear-gradient(45deg, #3b82f6 30%, #2563eb 90%)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontWeight: 600,
                  }}
                >
                  Help Us Improve CirrusCypher
                </Typography>

                {feedbackCategories.map((category, index) => (
                  <Box key={category.key} sx={{ mb: 4 }}>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.5 + index * 0.1 }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <category.icon
                          size={24}
                          style={{ color: category.color }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 1.5,
                            fontWeight: 600,
                            color: "text.primary",
                          }}
                        >
                          {category.title}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "left",
                          mb: 2,
                          ml: 4.5,
                          color: "text.secondary",
                          fontStyle: "italic",
                        }}
                      >
                        {category.description}
                      </Typography>
                      <IconRating
                        value={ratings[category.key]}
                        onChange={(value) =>
                          setRatings((prev) => ({
                            ...prev,
                            [category.key]: value,
                          }))
                        }
                        icon={category.icon}
                        activeColor={category.color}
                      />
                    </motion.div>
                  </Box>
                ))}

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.8 }}
                >
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    label="Additional Comments"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    sx={{ mb: 4 }}
                  />

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleSubmit}
                      startIcon={<Home size={24} />}
                      sx={{
                        px: 6,
                        py: 1.5,
                        borderRadius: 2,
                        fontSize: "1.1rem",
                        boxShadow: 3,
                        "&:hover": { boxShadow: 6 },
                      }}
                    >
                      Return Home
                    </Button>
                  </Box>
                </motion.div>
              </motion.div>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default MCQTestSubmitted;
